.noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
       user-select: none;
   }

.extendblock{

    width: 100vw;
    height: auto;
    background-color: #ffffff;

}
.extendblockitem{
    width: 100vw;
    height: 0vh;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.extendtitle{
    width: 100vw;
    height: auto;
    background-color: #fff;
    margin-top: 0px;
}

.scrollview{
    overflow-x: hidden;
    transition: all .3s;
    overflow-y: auto;

    width:100vw;
    height:100vh;
}
.scrollviewinner{
    min-height: 101vh;

}
::-webkit-scrollbar {
    display: none;

}
.swipercontainer{
    position: relative;
    top: 0px;
    background-color: transparent;

    transition:'all .6s'

}
.swiper{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

}